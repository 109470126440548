@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;
  overscroll-behavior: none;
  touch-action: none;
  overflow: hidden;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  height: 100%;
}

.tldraw__editor {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
